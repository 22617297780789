.ant-card-head-title {
  padding: 3px 0;
}

.ant-card-extra {
  padding: 3px 0;
}

.ant-card-head {
  min-height: 28px;
  padding: 0 10px;
}
