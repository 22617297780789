@import '../../node_modules/antd/dist/antd.css';
@import '../../node_modules/antd-button-color/dist/css/style.css';

:root{
  --main-font: 'Public Sans';
  --second-font: 'Paytone One';
  --thirth-font: 'Roboto';
  --main-color: #231955;
  --second-color: #1F4690;
  --thirth-color: #E8AA42;
  --forth-color: #FFE5B4;
  --border-radius: 10px;
  --fill-color: #E3E4E8;
  --fill-second-color: white;
  --fill-thre-color: #F9FBFC;
}

:root{
  --primary-color: #d4732b;
  --primary-color-light: rgba(212, 115, 43, 0.2);
  --primary-table-color: #d4732b;
  --primary-table-hover-color: #a65823;
  --border-color: #f7922b;
  --link-color: #1890ff;
  --success-color: #52c41a;
  --warning-color: #faad14;
  --error-color: #f5222d;
  --font-size-base: 14px;
  --heading-color: rgba(0, 0, 0, 0.85);
  --text-color: rgba(0, 0, 0, 0.65);
  --text-color-secondary: rgba(0, 0, 0, 0.45);
  --disabled-color: rgba(0, 0, 0, 0.25);
  --border-radius-base: 2px;
  --border-color-base: #d9d9d9;
  --box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: white;
}
.ant-layout-footer {
  padding: 13px;
  height: 48px;
}

.ant-layout-header {
  background: none;
  border-bottom: 1px solid var(--fill-color);
}

.ant-tabs-tab + .ant-tabs-tab{
  padding: 12px 10px;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--thirth-font), sans-serif;
  margin: 0 auto;
  /*background-color: #DFE1E3;*/
  background-color: #F9FBFC;
  font-size: 14px !important;
}

p {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}
input {outline: 0 !important;}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root{
  padding: 0 !important;
}

/*-------------------------------*/

.sentence{
  display: flex;
  justify-content: left;
  align-items: center;
}

.btn_action{
  margin-top: 20px;
}

.form_structure{
  margin: 0 auto;
  background-color: var(--fill-second-color);
  border-radius: var(--border-radius);
}

.form_structure input{
  /*border-radius: var(--border-radius);*/
  /*height: 40px;*/
}
.form_structure input:focus,
.form_structure input:hover {
  border-color: var(--main-color);
  box-shadow: none;
}

.form_structure h1,
.form_structure h2{
  text-align: center;
}

.form_action{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.form_action button{
  height: 100%;
  /*min-width: 80px;*/
}

.form_action .edit_btn{
  background-color: var(--second-color);
  color: white;
}

.form_action_table{
  display: flex;
  gap: 5px;
}

.list_structures{
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 10px 0;
}

.list_structures .item_structure{
  margin-right: 20px;
  border-radius: var(--border-radius);
  border: 1px solid var(--main-color);
  padding: 20px;
  width: 200px;
}

.search_data,
.sort_data{
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--fill-thre-color);
  -webkit-box-shadow: 0px 0px 40px -33px rgba(66, 68, 90, 1);
  -moz-box-shadow: 0px 0px 40px -33px rgba(66, 68, 90, 1);
  box-shadow: 0px 0px 40px -33px rgba(66, 68, 90, 1);
}
.search_block{
  width: 300px;
  position: relative;
}
.search_block input{
  padding-left: 30px;
}

.search_block .icon_search{
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 20px;
  color: gray;
}

.operation_block{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.operation_block>div{
  margin: 0 10px;
}

.pagination_block{
  position: absolute;
  bottom: 10px;
}

.type_view_block{
  padding: 20px 0;
}

.gallery_structures{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.header_structure{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header_structure_caption{
 display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.header_structure_caption p{
  font-size: 20px;
}

div.jsoneditor-outer.has-main-menu-bar{
  margin-bottom: -26px !important;
  padding-bottom: 26px !important;
}

.view_table .ant-table-cell-fix-left-first:after,
.view_table .ant-table-cell-fix-left-last:after {
  box-shadow: inset 10px 0 8px -8px #00000026;
}

.view_table .ant-table-cell-fix-right-first:after,
.view_table .ant-table-cell-fix-right-last:after {
  box-shadow: inset -10px 0 8px -8px #00000026;
}

.action_button_menu{
  padding: 10px;
}
.action_button_menu li{
  padding: 10px;
}